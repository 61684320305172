import { Node } from '../node/node';

export class PriorityQueue {
  private _heap: Array<Node>;
  private _comparator: Function;
  private top: number;
  private parent: Function;
  private left: Function;
  private right: Function;

  constructor(comparator = (a: Node, b: Node) => (a.h + a.g) <= (b.h + b.g)) {
    this._heap = [];
    this._comparator = comparator;
    this.top = 0;
    this.parent = i => ((i + 1) >>> 1) - 1;
    this.left = i => (i << 1) + 1;
    this.right = i => (i + 1) << 1;
  }

  size() {
    return this._heap.length;
  }
  isEmpty() {
    return this.size() == 0;
  }
  peek() {
    if (this.size() <= 0) return;
    return this._heap[this.top];
  }
  push(...values) {
    values.forEach(value => {
      this._heap.push(value);
      this._siftUp();
    });
    return this.size();
  }
  pop() {
    if (this.size() <= 0) return;
    const poppedValue = this.peek();
    const bottom = this.size() - 1;
    if (bottom > this.top) {
      this._swap(top, bottom);
    }
    this._heap.pop();
    this._siftDown();
    return poppedValue;
  }
  replace(value) {
    const replacedValue = this.peek();
    this._heap[this.top] = value;
    this._siftDown();
    return replacedValue;
  }
  contains(node: Node): boolean {
      for (let i = 0; i < this._heap.length; i++) {
          if (this._heap[i].x === node.x && this._heap[i].y === node.y) return true;
      }
      return false;
  }
  _greater(i, j) {
    if (this._heap[i] && !this._heap[j]) return this._heap[i];
    else if (!this._heap[i] && this._heap[j]) return this._heap[j];
    return this._comparator(this._heap[i], this._heap[j]);
  }
  _swap(i, j) {
    [this._heap[i], this._heap[j]] = [this._heap[j], this._heap[i]];
  }
  _siftUp() {
    let node = this.size() - 1;
    while (node > this.top && this._greater(node, this.parent(node))) {
      this._swap(node, this.parent(node));
      node = this.parent(node);
    }
  }
  _siftDown() {
    let node = top;
    while (
      (this.left(node) < this.size() && this._greater(this.left(node), node)) ||
      (this.right(node) < this.size() && this._greater(this.right(node), node))
    ) {
      let maxChild = (this.right(node) < this.size() && this._greater(this.right(node), this.left(node))) ? this.right(node) : this.left(node);
      this._swap(node, maxChild);
      node = maxChild;
    }
  }
}