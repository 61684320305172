import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-history-step',
  templateUrl: './history-step.component.html',
  styleUrls: ['./history-step.component.scss']
})
export class HistoryStepComponent implements OnInit {

  @ViewChild('card') elementRef: ElementRef;
  public state = 'default';
  public halfRotated = false;

  @Input() company: string;
  @Input() shortSummary: string;
  @Input() longSummary: string;
  @Input() imgSrc: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() title: string;
  @Input() linkToCompany: string;
  @Input() competencies: [
    {
      title: string,
      img: string,
      description: string
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

  flipCardOver(): void {
    if (this.elementRef.nativeElement.classList.contains('card-flipped')) {
      this.elementRef.nativeElement.classList.remove('card-flipped');
    } else {
      this.elementRef.nativeElement.classList.add('card-flipped');
    }
  }
}
