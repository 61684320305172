import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss']
})
export class SkillComponent implements OnInit {

  @Input() level: number;
  @Input() technology: string;
  @Input() logo: string;
  @Input() yoe: string;

  public stars: number[];
  public blackStars: number[];

  constructor() {
  }

  ngOnInit(): void {
    this.stars = new Array<number>();
    for (let i = 0; i < this.level; i++) {
      this.stars.push(0);
    }
    if (this.level < 5) {
      this.blackStars = Array();
      for (let i = this.level; i < 5; i++) {
        this.blackStars.push(0);
      }
    } else {
      this.blackStars = null;
    }
  }
}
