<div class="content" role="main">
  <div class="wrapper-pathfinder">
    <mdb-navbar id="navbar" SideClass="navbar navbar-expand-lg navbar-dark" [containerInside]="false">
      <mdb-navbar-brand><a class="navbar-brand" href="#"></a></mdb-navbar-brand>
      <links>
        <ul class="nav ml-auto">
          <li class="nav-item"><span class="nav-link waves-light" mdbWavesEffect routerLink="/home"><mdb-icon fas icon="home"></mdb-icon> Home</span></li>
          <li class="nav-item"><span class="nav-link waves-light" mdbWavesEffect routerLink="/sorting"><mdb-icon fas icon="signal"></mdb-icon> Sorting</span></li>
        </ul>
      </links>
    </mdb-navbar>
    <div id="stars"></div>
    <div id="stars2"></div>
    <div id="stars3"></div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-10 col-xl-6 offset-lg-1 offset-xl-3">
          <mdb-card dark="true">
            <mdb-card-body class="elegant-color white-text">
              <mdb-card-title>
                <h4>Pathfinder</h4>
              </mdb-card-title>
              <div class="form-group col-sm-12">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <label>Algorithm: </label>&nbsp;&nbsp;
                      <i class="fas fa-question-circle primary-color-font cursor-help"
                        mdbTooltip="All these algorithms will guarantee the shortest path except DFS. It is also worth noting that because this graph is unweighted (each node traversal costs 1), BFS and Dijkstra will have very similar results." placement="right"
                      ></i>
                      <select class="browser-default dark-select" (ngModelChange)="updateAlgorithm($event)" [disabled]="isRunning" [(ngModel)]="algorithm">
                        <option value="None">Select an algorithm</option>
                        <option value="A*">A*</option>
                        <option value="Dijkstra">Dijkstra</option>
                        <option value="BFS">Breadth-First Search</option>
                        <option value="DFS">Depth-First Search</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="container" [hidden]="algorithm !== 'A*'">
                  <div class="row">
                    <div class="col-sm-12">
                      <label>Heuristic: </label>&nbsp;&nbsp;
                      <i class="fas fa-question-circle primary-color-font cursor-help"
                        mdbTooltip="Euclidean should be preferred if diagonal traversal is enabled. Manhattan should be preferred otherwise" placement="right"
                      ></i>
                      <select class="browser-default dark-select" (ngModelChange)="updateHeuristic($event)" [disabled]="isRunning" [(ngModel)]="heuristic">
                        <option value="None">Select a heuristic</option>
                        <option value="Manhattan">Manhattan</option>
                        <option value="Euclidean">Euclidean</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <label>Visualization speed: </label>
                      <input type="range" [(ngModel)]="speed" (ngModelChange)="speedChange($event)" class="custom-range" value="30" min="0" max="40" />
                    </div>
                    <div class="col-sm-12">
                      <div class="custom-control custom-checkbox">
                        <input id="useDiagonals" type="checkbox" class="form-check-input" [disabled]="isRunning" [(ngModel)]="useDiagonals">
                        <label class="form-check-label" for="useDiagonals">Use diagonals</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12 col-lg-6">
                      <button mdbBtn type="button" color="danger" class="waves-light col-sm-12" [disabled]="isRunning" (click)="reset()" mdbWavesEffect>Reset</button>
                    </div>
                    <div class="col-sm-12 col-lg-6">
                      <button mdbBtn type="button" color="danger" class="waves-light col-sm-12" [disabled]="isRunning" (click)="clearWalls()" mdbWavesEffect>Clear walls</button>
                    </div>
                    <div class="col-sm-12">
                      <button mdbBtn type="button" color="primary" class="waves-light col-sm-12" [disabled]="isRunning || !isNotConfigured" (click)="findPath()" mdbWavesEffect>Start</button>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="legend legend-wall">
                      </div>
                      <div class="legend-text">
                        <span>Wall</span>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="legend legend-visited">
                      </div>
                      <div class="legend-text">
                        <span>Visited</span>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="legend legend-explored">
                      </div>
                      <div class="legend-text">
                        <span>Explored</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>
    <div #wrapper class="col-sm-12 col-md-12 margin-top-2">
      <canvas #pathfinder (mousemove)="addWall($event)" (mousedown)="addWall($event)" id="pathfinder" class="pathfinder-canvas"></canvas>
    </div>
  </div>
</div>
