import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  private intro: string = "Hi!\nI'm Christophe!";
  typedIntro: string = "";
  private summary = "I am a software engineer at Google"
  typedSummary: string = "";

  scrollToElement($element): void {
    const element = window.document.getElementById($element);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  introTyping(that) {
    let total_length = that.intro.length;
    let current_length = that.typedIntro.length;
    if (current_length < total_length) {
      that.typedIntro += that.intro[current_length];
      setTimeout(that.introTyping, 80, that);
    } else {
      that.summaryTyping(that);
    }
  }

  summaryTyping(that) {
    let total_length = that.summary.length;
    let current_length = that.typedSummary.length;
    if (current_length < total_length) {
      that.typedSummary += that.summary[current_length];
      setTimeout(that.summaryTyping, 80, that);
    }
  }

  ngOnInit() {
    this.introTyping(this);
  }
}
