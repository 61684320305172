<div class="container "
     data-aos="zoom-in-down"
     data-aos-duration="500"
     data-aos-easing="ease-in-sine">
  <div class="row">
    <div class="col-md-12 mx-auto">
      <mdb-card dark="true">
        <div class="view overlay waves-light" mdbWavesEffect>
          <mdb-card-img src="/assets/img/me.jpg" mdbTooltip="Picture by Simon Rochfort"
                        placement="right"></mdb-card-img>
          <div class="bottom-right">
            <a href="https://www.simonrochfortphoto.com/" class="font-small">
              <span class="waves-light white-text font-small" mdbWavesEffect>Picture by Simon Rochfort.
              </span>
            </a>
          </div>
        </div>
        <mdb-card-body class="elegant-color white-text">
          <mdb-card-title>
            <h4>Contact me!</h4>
          </mdb-card-title>
          <a class="px-2 icons-sm tw-ic" href="https://twitter.com/cpinelli_dev" target="_blank">
            <mdb-icon fab icon="twitter" class="twitter-color"></mdb-icon>
          </a>
          <a class="px-2 icons-sm li-ic" href="https://www.linkedin.com/in/christophe-pinelli-40b776106/"
             target="_blank">
            <mdb-icon fab icon="linkedin-in" class="linkedin-color"></mdb-icon>
          </a>
          <hr class="hr-light">
          <mdb-card-text class="white-text">
            You can also reach out to me via my email address: <a href="mailto: christophe@c-pinelli.fr">christophe@c-pinelli.fr </a>
            <br>
            <a href="/assets/files/Resume.pdf" target="_blank">My resume is also available here.</a>
          </mdb-card-text>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>
