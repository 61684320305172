import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PathfinderComponent } from "./pathfinder/pathfinder.component";
import { HomeComponent } from "./home/home.component";
import { SortingComponent } from "./sorting/sorting.component";

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'pathfinder', component: PathfinderComponent},
  {path: 'sorting', component: SortingComponent},
  {path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
