import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HistoryComponent } from './history/history.component';
import { PathfinderComponent } from './pathfinder/pathfinder.component';
import { SkillComponent } from './skill/skill.component';
import { SkillsComponent } from './skills/skills.component';
import { ContactComponent } from './contact/contact.component';
import { HistoryStepComponent } from './history-step/history-step.component';
import { SortingComponent } from './sorting/sorting.component';

@NgModule({
  declarations: [
    HomeComponent,
    PathfinderComponent,
    AppComponent,
    HistoryStepComponent,
    HistoryComponent,
    SkillComponent,
    SkillsComponent,
    SortingComponent,
    ContactComponent
  ],
  imports: [
    MDBBootstrapModule.forRoot(),
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
