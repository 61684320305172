<div #card class="card-container margin-top-10">
  <div class="card-flip white-text">
    <div class="card front">
      <mdb-card dark="true">
        <a href="{{linkToCompany}}" target="_blank">
          <img src="{{imgSrc}}" class="card-img-top img-fluid">
        </a>
        <mdb-card-body class="elegant-color white-text">
          <div class="card-block">
            <mdb-card-title>
              <h4 class="sec-color">{{company}}</h4>
            </mdb-card-title>
            <hr class="hr-light">
            <mdb-card-text class="white-text">
              <p>{{shortSummary}}</p>
            </mdb-card-text>
            <a class="white-text d-flex justify-content-end cursor-pointer" (click)="flipCardOver()">
              <h5 class="waves-light white-text" mdbWavesEffect>Read more
                <span>
                  <mdb-icon fas icon="chevron-right" class="pl-2 waves-light" mdbWavesEffect></mdb-icon>
                </span>
              </h5>
            </a>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
    <div class="card back h-100">
      <mdb-card dark="true" class="h-100">
        <mdb-card-body class="elegant-color white-text">
          <div class="card-block">
            <mdb-card-title>
              <h5 class="sec-color">{{title}}</h5>
            </mdb-card-title>
            <hr class="hr-light">
            <mdb-card-text class="white-text">
              <div class="h-286 overflow-y-auto scrollbar scrollbar-primary">
                <ul class="list-unstyled">
                  <li *ngFor="let competency of competencies; index as i" [className]="i %2 === 0 ? 'media' : 'media my-4'">
                    <img src="{{competency.img}}" class="mr-3 max-size-94 size-94" alt="...">
                    <div class="media-body">
                      <h5 class="mt-0 mb-1">{{competency.title}}</h5>
                      <p class="text-justify">{{competency.description}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </mdb-card-text>
            <mdb-card-text class="justify-content-center" align="center">
              <p class="sec-color">
                From <b>{{startDate}}</b> to <b>{{endDate}}</b>
              </p>
            </mdb-card-text>
            <a class="white-text d-flex justify-content-end cursor-pointer" (click)="flipCardOver()">
              <h5 class="waves-light white-text" mdbWavesEffect>Read less
                <span>
                  <mdb-icon fas icon="chevron-right" class="pl-2 waves-light" mdbWavesEffect></mdb-icon>
                </span>
              </h5>
            </a>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>
