import {Component, OnInit} from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  public professionalExperiences = [
    {
      company: 'Google',
      shortSummary: 'I have been working as a Senior Software Engineer (L5) at Google since March 2022.',
      competencies: [
      ],
      image: '/assets/img/google.png',
      startDate: 'March 2022',
      endDate: 'Present',
      title: 'Senior Software Engineer',
      link: 'https://www.google.com/'
    },
    {
      company: 'Instacart',
      shortSummary: 'I have worked as a Senior Software Engineer (L5) at Instacart for 10 months.',
      competencies: [
        {
          title: 'Ruby',
          description: 'Ruby is t he main language used at Instacart.',
          img: '/assets/img/ruby.png'
        },
        {
          title: 'Rails',
          description: 'Rails is used for REST APIs in the Connect org at Instacart..',
          img: '/assets/img/rails.png'
        },
        {
          title: 'Java',
          description: 'I used Java for one of the SDKs Connect has been developing.',
          img: '/assets/img/java.png'
        },
        {
          title: 'Redis',
          description: 'Redis was the cache system we were using in the Connect org at Instacart',
          img: '/assets/img/redis.png'
        },
        {
          title: 'gRPC',
          description: 'gRPC is the communication framework used across services at Instacart',
          img: '/assets/img/grpc.png'
        },
        {
          title: 'React',
          description: 'React is the frontend framework used by Connect for its Order Status Page.',
          img: '/assets/img/react.png'
        }
      ],
      image: '/assets/img/instacart.png',
      startDate: 'June 2021',
      endDate: 'February 2022',
      title: 'Senior Software Engineer',
      link: 'https://www.instacart.com/'
    },
    {
      company: 'Mastercard',
      shortSummary: 'I have worked as a Senior Software Development Engineer (SDE III) at Mastercard in Vancouver for 7 months',
      competencies: [
        {
          title: 'Spring',
          description: 'Spring boot was the framework all our web services have been built on, we are using a couple components including Spring Security and Spring Cloud',
          img: '/assets/img/spring-boot.png'
        },
        {
          title: 'Redis',
          description: 'Redis was the cache system we were using in the Data Insight Platform team at Mastercard',
          img: '/assets/img/redis.png'
        },
        {
          title: 'HashiCorp Vault',
          description: 'We used Vault to store secrets we used in the applications',
          img: '/assets/img/vault.png'
        },
        {
          title: 'Scala',
          description: 'Scala is the main programming language we use for data processing software development',
          img: '/assets/img/scala.png'
        },
        {
          title: 'Postgresql',
          description: 'The relational database was created on Postgresql and was used across multiple services',
          img: '/assets/img/postgres.png'
        },
        {
          title: 'Jenkins',
          description: 'Jenkins is the tool we are using for our CI/CD pipelines',
          img: '/assets/img/jenkins.png'
        }
      ],
      image: '/assets/img/mastercard.png',
      startDate: 'December 2020',
      endDate: 'June 2021',
      title: 'Senior Software Dev. Engineer',
      link: 'https://www.mastercard.com'
    },
    {
      company: 'AWS',
      shortSummary: 'I worked as a Software Development Engineer (SDE) at AWS Service Catalog in Vancouver, for about 10 months',
      competencies: [
        {
          title: 'Guice (Java)',
          description: 'Guice was introduced in the codebase for more recent features and was the main framework of the services',
          img: '/assets/img/java.png'
        },
        {
          title: 'Spring',
          description: 'Spring was the second IoC framework I used during my experience at AWS, it is the main framework used for older code',
          img: '/assets/img/spring-boot.png'
        },
        {
          title: 'Python',
          description: 'Python was the main language used in the Lambda function that I developed',
          img: '/assets/img/python.png'
        },
        {
          title: 'AWS SQS',
          description: 'I used SQS as a FIFO queue for inter-service communication',
          img: '/assets/img/aws-sqs.png'
        },
        {
          title: 'AWS CodeCommit',
          description: 'I used AWS CodeCommit internally to store some data used in the open source repository of the team',
          img: '/assets/img/codecommit.png'
        },
        {
          title: 'AWS DynamoDB',
          description: 'DynamoDB was the primary datastore that is used across the organization due to its high availability and speed',
          img: '/assets/img/dynamodb.png'
        },
        {
          title: 'AWS Step Functions',
          description: 'I created a Step Function instance for one of our projects, which was used for a sequence of Lambda functions',
          img: '/assets/img/step-functions.png'
        },
        {
          title: 'AWS Lambda',
          description: 'Part of the applications I developed and maintained was serverless, for which Lambda is the technology we used',
          img: '/assets/img/lambda.png'
        }
      ],
      image: '/assets/img/aws.png',
      startDate: 'Sept 2019',
      endDate: 'Aug 2020',
      title: 'Software Developement Engineer',
      link: 'https://aws.amazon.com/'
    },
    {
      company: 'Amazon',
      shortSummary: 'I started my journey at Amazon as a Frontend Engineer (which is actually a full-stack position) in the Alexa organization',
      competencies: [
        {
          title: 'Guice (Java)',
          description: 'Guice was introduced in the codebase for more recent features and was the main framework of the services',
          img: '/assets/img/java.png'
        },
        {
          title: 'Spring',
          description: 'Spring was the second IoC framework I used during my experience at Amazon, it is the main framework used for older code',
          img: '/assets/img/spring-boot.png'
        },
        {
          title: 'React',
          description: 'React is the core framework used in the team I worked with, it was used to adapt to all the Alexa devices equipped with a monitor',
          img: '/assets/img/react.png'
        }
      ],
      image: '/assets/img/amazon.png',
      startDate: 'Mai 2019',
      endDate: 'Sept 2019',
      title: 'Frontend Engineer',
      link: 'https://www.amazon.com/'
    },
    {
      company: 'Elastic Path',
      shortSummary: 'I had the great opportunity to work as a software engineer at Elastic Path, which is one of my best experience',
      competencies: [
        {
          title: 'Spring',
          description: 'Spring was the main backend framework I used during my experience at Elastic Path, all services were working in a Spring environment',
          img: '/assets/img/spring.png'
        },
        {
          title: 'React',
          description: 'React was the framework we used for most of our customer demos, it was designed by a remote team in Toronto and is available easily for customers',
          img: '/assets/img/react.png'
        },
        {
          title: 'Drupal',
          description: 'Drupal was the second technology we used to create demos for our customers, it was an important step to bring Drupal in as it is a very popular CMS',
          img: '/assets/img/drupal.png'
        },
        {
          title: 'Jenkins',
          description: 'Jenkins was the tool we used for our CI/CD pipeline, we had multiple scripts depending on the demo technology we were using to automate its deployment',
          img: '/assets/img/jenkins.png'
        },
        {
          title: 'AWS',
          description: 'The demos were hosted in AWS where we used EC2 instances, Route53 to map the instances to an easily readable URL and S3 to store images',
          img: '/assets/img/aws-dep.png'
        }
      ],
      image: '/assets/img/elasticpath.png',
      startDate: 'Oct 2018',
      endDate: 'May 2019',
      title: 'Software Engineer',
      link: 'https://www.elasticpath.com/'
    },
    {
      company: 'CareCru',
      shortSummary: 'CareCru is a startup which develops an application used by dental clinics to simplify their customer appointments',
      competencies: [
        {
          title: 'Spring Boot',
          description: 'Spring was the main backend framework I used during my experience at CareCru, services were developed with Spring MVC, Spring Cloud and NetflixOSS',
          img: '/assets/img/spring-boot.png'
        },
        {
          title: 'Postgresql',
          description: 'The relational database was created on Postgresql and was used across multiple services',
          img: '/assets/img/postgres.png'
        },
        {
          title: 'Docker',
          description: 'Docker was the technology used to containerize the backend services I developed at CareCru, they were then hosted on AWS EC2',
          img: '/assets/img/docker.png'
        },
        {
          title: 'AWS (EC2, RDS)',
          description: 'The applications were hosted in EC2 instances, the relational database was instantiated in RDS',
          img: '/assets/img/aws-dep.png'
        }
      ],
      image: '/assets/img/carecru.png',
      startDate: 'Jan 2018',
      endDate: 'Oct 2018',
      title: 'Software Engineer',
      link: 'https://carecru.com/'
    },
    {
      company: 'Worldline',
      shortSummary: 'I worked at Worldline for one year during the last year of my scholarship which was based on the sandwich course model.',
      competencies: [
        {
          title: 'Spring Boot',
          description: 'Spring was the main backend framework I used during my experience at CareCru, services were developed with Spring MVC, Spring Cloud and NetflixOSS',
          img: '/assets/img/spring-boot.png'
        },
        {
          title: 'Ember',
          description: 'Ember was the main framework used for frontend development in the team I was in, some older applications were developed on Angular',
          img: '/assets/img/ember.png'
        },
        {
          title: 'MySQL',
          description: 'MySQL was the main database management system I used at Worldline, these were created by the operation team and managed by the development team',
          img: '/assets/img/mysql.png'
        },
        {
          title: 'Docker',
          description: 'Docker was the technology used to containerize the backend services I developed at Worldline, they were then hosted on a proprietary cloud',
          img: '/assets/img/docker.png'
        },
        {
          title: 'Gitlab CI',
          description: 'Gitlab CI was the tool we used for our CI/CD pipeline, we had multiple Yaml scripts depending on the stages the application was deployed to',
          img: '/assets/img/gitlab.png'
        },
      ],
      image: '/assets/img/worldline.png',
      startDate: 'Sept 2016',
      endDate: 'Sept 2017',
      title: 'Software Engineer',
      link: 'https://worldline.com/'
    }
  ];

  public degrees = [
    {
      school: 'Université Claude Bernard Lyon 1',
      shortSummary: 'I graduated with a MSc. in Information Technologies and Web (TIW, French: Technologies de l\'information et Web). ' +
        'At the same time I was working as a full-time software engineer apprentice at Worldline (Atos). The idea of this course was to both work in a company and study at the same time.',
      competencies: [
        {
          title: 'Graph theory (Java)',
          description: 'During this course I learned about graph traversal algorithms, such as DFS and BFS, along with pathfinding algorithms such as Dijkstra and A*.',
          img: '/assets/img/java.png'
        },
        {
          title: 'Software Architecture (Java)',
          description: 'While the subject was not unknown for me at that time, due to my past experiences, this is where I dived deep into the different software architectures, and because I liked it, that is what I specialized in. I learned about microservices, SOA and distributed systems in general.',
          img: '/assets/img/java.png'
        },
        {
          title: 'Software deployment (Docker)',
          description: 'This course was mostly technical, we studied software containerization with Docker and started working on deployment automation systems. We mostly worked with Docker, Docker-compose and Docker-swarm with software written in Java and Javascript.',
          img: '/assets/img/docker.png'
        },
        {
          title: 'Inversion of Control (Spring)',
          description: 'This course was an advanced software development course where we dived deep into the Spring framework and learned about IoC containers, how they work, how we can configure them and the different scopes they bring.',
          img: '/assets/img/spring.png'
        },
        {
          title: 'Spring Framework',
          description: 'This was a preliminary course required for the Inversion of Control course mentioned before, we learned how to create a full stack Spring web application using Spring MVC and template engines such as JSP or Thymeleaf.',
          img: '/assets/img/spring.png'
        },
        {
          title: 'Frontend engineering (Angular)',
          description: 'During this course, we learned about consuming backend API and use them to visualize data, along with more advanced technologies such as critical rendering path, we mostly used Angular and BackboneJS.',
          img: '/assets/img/angular.png'
        },
        {
          title: 'Backend engineering (NodeJS)',
          description: 'This course got the class started on backend API, we would learn how to create a public or private API with CRUD operations which would store information in a datastore (MySQL and MongoDB)',
          img: '/assets/img/nodejs.png'
        },
        {
          title: 'Apache Hadoop',
          description: 'This was a preliminary course to learn about MapReduce and its application within the Hadoop software (including Zookeeper) and learned about HDFS.',
          img: '/assets/img/hadoop.png'
        },
        {
          title: 'Apache Spark',
          description: 'After learning about Hadoop, we started using Spark to compute large datasets simultaneously.',
          img: '/assets/img/spark.png'
        },
        {
          title: 'Apache Kafka',
          description: 'Kafka is the system we have used to learn about messaging and queues and their application in distributed systems.',
          img: '/assets/img/kafka.png'
        },
        {
          title: 'Cloud Services (OpenStack)',
          description: 'This was the final cloud computing course we had, most of the practical work were about deploying Docker containers (or clusters) on a private OpenStack server.',
          img: '/assets/img/openstack.png'
        },
        {
          title: 'Big Data (SQL Cubes & Sharding)',
          description: 'This was a theoretical course where we learned about OLAP and multidimensional databases. We also learned about horizontal, vertical and mixed sharding, which we applied in technical projects.',
          img: '/assets/img/mysql.png'
        }
      ],
      image: '/assets/img/ucbl.png',
      startDate: 'Aug 2015',
      endDate: 'Sept 2012',
      title: 'Student - MSc. Computer Science',
      link: 'https://www.univ-lyon1.fr/'
    },
    {
      school: 'Université Claude Bernard Lyon 1',
      shortSummary: 'In 2015, I graduated with a BSc. in Computer Science (French: Informatique), it was at that time that I learned the basics of algorithm and data structures.' +
        'This course enabled me to get started professionally as I could join two different companies as an intern.',
      competencies: [
        {
          title: 'Algorithms (C/C++)',
          description: 'This was a preliminary course to learn mostly about C and C++ and their libraries such as STD, we learned about sorting and logic gates.',
          img: '/assets/img/c++.png'
        },
        {
          title: 'Data Structures (C/C++)',
          description: 'This was a preliminary course to learn about main data structures, such as dynamic arrays, binary trees and linked lists.',
          img: '/assets/img/c++.png'
        },
        {
          title: 'Advanced algorithms and data structures (C/C++)',
          description: 'This course was about advanced algorithms and advanced data structures, we learned about more efficient sorting, such as quicksort, mergesort and heapsort, and complex data structures, such as AVL trees, red-black trees, skip-lists, graphs, stacks and queues.',
          img: '/assets/img/c++.png'
        },
        {
          title: 'SQL and RDBMS',
          description: 'Preliminary course where we learned about simple data storage, RDBMS and data normalization (Normal forms).',
          img: '/assets/img/mysql.png'
        },
        {
          title: 'Backend development (PHP)',
          description: 'Basic technical course to create a server application to our frontend, this course was mostly about a 3-month project where we created a whole web application to simulate a library.',
          img: '/assets/img/php.png'
        },
        {
          title: 'Frontend development (JavaScript)',
          description: 'This was a course where we started working on static web pages with HTML, CSS and JavaScript events.',
          img: '/assets/img/javascript.png'
        }
      ],
      image: '/assets/img/ucbl.png',
      startDate: 'Sept 2015',
      endDate: 'Sept 2017',
      title: 'Student - BSc. Software Engineering',
      link: 'http://master-info.univ-lyon1.fr/TIW/'
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
    AOS.init();
  }
}
