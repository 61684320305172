import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Node} from './node/node';
import {PriorityQueue} from './priority-queue/priority-queue';

@Component({
  selector: 'app-pathfinder',
  templateUrl: './pathfinder.component.html',
  styleUrls: ['./pathfinder.component.scss']
})
export class PathfinderComponent implements OnInit {

  @ViewChild('pathfinder', {static: true}) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('wrapper', {static: true}) wrapper: ElementRef<HTMLDivElement>;

  private blocks: Node[][];
  private height: number = window.innerHeight * 0.48;
  private width: number = window.innerWidth * 0.98;
  private columns: number = Number((this.width / 25).toFixed(0));
  private rows: number = Number((this.height / 25).toFixed(0));
  private blockSize: number = this.width / this.columns;
  private startingPoint: Node;
  private endingPoint: Node;
  private currentOveredBlock: {
    x: number,
    y: number
  };
  private openNodes: PriorityQueue;

  private closedNodes: Node[];
  private currentNode: Node;
  private path: Node[];
  private pathSpeed: number;
  private nodeSpeed: number;

  public isRunning: boolean;
  public isNotConfigured: boolean;
  public heuristic: string;
  public useDiagonals: false;
  public speed: number;
  public algorithm: string;

  constructor() {
  }

  ngOnInit(): void {
    this.algorithm = 'None';
    this.width = this.blockSize * this.columns;
    this.height = this.blockSize * this.rows;
    this.blocks = [];
    this.openNodes = new PriorityQueue();
    this.closedNodes = [];
    this.path = [];
    this.isRunning = false;
    this.canvas.nativeElement.addEventListener('touchmove', this.addWall.bind(this));
    this.canvas.nativeElement.addEventListener('touchstart', this.addWall.bind(this));
    this.canvas.nativeElement.width = this.width;
    this.canvas.nativeElement.height = this.height;

    for (let i = 0; i < this.rows; i++) {
      this.blocks[i] = [];
      for (let j = 0; j < this.columns; j++) {
        this.blocks[i][j] = new Node(false, j, i);
      }
    }

    this.putStartPoint();
    this.putEndPoint();
    this.heuristic = 'None';
    this.speed = 30;
    this.nodeSpeed = 40.5 - this.speed;
    this.pathSpeed = 55 - this.speed;
    this.isNotConfigured = false;
  }

  speedChange(e): void{
    this.nodeSpeed = 40.5 - e;
    this.pathSpeed = 55 - e;
  }

  updateHeuristic(e): void {
    this.isNotConfigured = e === 'Manhattan' || e === 'Euclidean' && this.algorithm !== 'None';
  }

  updateAlgorithm(e): void {
    if (e === 'A*') {
      this.isNotConfigured = this.heuristic !== 'None';
    } else {
      this.isNotConfigured = e !== 'None';
    }
  }

  traceCells(ctx): void{
    ctx.strokeStyle = 'rgba(0,0,0,1)';
    ctx.lineWidth = 0.5;
    for (let i = 0; i < this.columns; i++) {
      const x = Math.floor(i * this.width / this.columns);
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, this.height);
      ctx.stroke();
    }
    for (let j = 0; j < this.rows; j++) {
      const y = Math.floor(j * this.height / this.rows);
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(this.width, y);
      ctx.stroke();
    }
  }

  addWall(e): void {
    if (this.isRunning) { return; }
    let offsetX = 0;
    let offsetY = 0;
    if (this.isTouchEvent(e)) {
      e.preventDefault();
      const rect = (e.target as HTMLCanvasElement).getBoundingClientRect();
      offsetX = e.targetTouches[0].clientX - rect.left;
      offsetY = e.targetTouches[0].clientY - rect.top;
    } else {
      if (e.buttons !== 1) {
        return;
      }

      offsetX = e.offsetX;
      offsetY = e.offsetY;
    }
    const block = [Number((offsetX / this.blockSize).toFixed(0)), Number((offsetY / this.blockSize).toFixed(0))];
    if (!this.canUpdate(e, block)) {
      return;
    }
    this.updateLastBlock(e, block);
    this.addOrRemoveWall(block);
  }

  fill(block, color): void {
    const ctx = this.getContext();
    const x = block[0] * this.blockSize;
    const y = block[1] * this.blockSize;

    ctx.fillStyle = color;
    ctx.clearRect(x, y, this.blockSize, this.blockSize);
    ctx.fillRect(x + 1, y + 1, this.blockSize - 2, this.blockSize - 2);
  }

  clear(block): void {
    const ctx = this.getContext();
    const x = block[0] * this.blockSize;
    const y = block[1] * this.blockSize;

    ctx.clearRect(x, y, this.blockSize, this.blockSize);
  }

  getContext(): CanvasRenderingContext2D {
    return this.canvas.nativeElement.getContext('2d');
  }

  putStartPoint(): void {
    const randomX = Math.floor(Math.random() * Math.floor(this.columns));
    const randomY = Math.floor(Math.random() * Math.floor(this.rows));
    this.startingPoint = new Node(false, randomX, randomY);
    this.startingPoint.g = 0;
    this.fill([randomX, randomY], 'rgba(0,170,0,1)');
  }

  putEndPoint(): void {
    const randomX = Math.floor(Math.random() * Math.floor(this.columns));
    const randomY = Math.floor(Math.random() * Math.floor(this.rows));
    this.endingPoint = new Node(false, randomX, randomY);
    this.fill([randomX, randomY], 'rgba(170,0,0,1)');
  }

  updateLastBlock(e, block): void {
    if (e.type === 'mousemove' || e.type === 'touchmove') {
      this.currentOveredBlock = {
        x: block[0],
        y: block[1]
      };
    } else {
      this.currentOveredBlock = {
        x: -1,
        y: -1
      };
    }
  }

  canUpdate(e, block): boolean {
    if (e.type !== 'mousedown' && this.currentOveredBlock &&
      this.currentOveredBlock.x === block[0] && this.currentOveredBlock.y === block[1]) {
      return false;
    }
    if (this.startingPoint.x === block[0] && this.startingPoint.y === block[1]) { return false; }
    if (this.endingPoint.x === block[0] && this.endingPoint.y === block[1]) { return false; }
    if (this.isRunning) { return false; }
    return true;
  }

  addOrRemoveWall(block): void {
    if (this.blocks[block[1]][block[0]].isWall) {
      this.blocks[block[1]][block[0]].isWall = false;
      this.clear(block);
    } else {
      this.blocks[block[1]][block[0]].isWall = true;
      this.fill(block, 'rgba(53,53,53,1)');
    }
  }

  isValid(x, y): boolean {
    return x >= 0 && x < this.columns && y >= 0 && y < this.rows;
  }

  isWall(x, y): boolean {
    return this.blocks[y][x].isWall;
  }

  isEnd(x, y): boolean {
    return this.endingPoint.x === x && this.endingPoint.y === y;
  }

  addCost(currentCost): number {
    return currentCost + 1;
  }

  computeHeuristics(x1, y1, x2, y2): number {
    if (this.heuristic === 'Manhattan') {
      return Math.abs(x1 - x2) + Math.abs(y1 - y2);
    }
    if (this.heuristic === 'Euclidean') {
      const xDiff = x1 - x2;
      const yDiff = y1 - y2;
      return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
    }
  }

  pushAnimationColor(animations: Array<Array<number>>, x: number, y: number, distance: number, explored: boolean) {
    let blue = explored ? 650 : 229;
    let green = explored ? 190 : 174;
    let red = explored ? 40 : 160;
    let alpha = explored ? '0.6' : '0.4';
    this.pushAnimation(animations, x, y, red, green, blue, alpha);
  }

  pushAnimation(animations, x, y, r, g, b, a) {
    const animation = [];
    animation[0] = x;
    animation[1] = y;
    animation[2] = r;
    animation[3] = g;
    animation[4] = b;
    animation[5] = a;
    animations.push(animation);
  }

  animate(animations): void {
    this.animateSynchronously(animations).then(() => {
      this.printPath().then(() => this.isRunning = false);
    });
  }

  async printPath() {
    for (let i = 1; i < this.path.length - 1; i++) {
      await new Promise((resolve) => {
        setTimeout(() => {
          const green = 255;
          this.fill([this.path[i].x, this.path[i].y], 'rgba(0, ' + green + ' ,0,0.8)');
          resolve();
        }, this.pathSpeed);
      })
    }
  }

  async animateSynchronously(animations) {
    for (let i = 0; i < animations.length; i++) {
      const current = animations[i];
      const x = current[0];
      const y = current[1];
      const r = current[2];
      const g = current[3];
      const b = current[4];
      const a = current[5];
      await new Promise((resolve) => {
        setTimeout(() => {
          this.fill([x, y], 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')');
          resolve();
        }, this.nodeSpeed);
      });
    }
  }

  reset(): void {
    for (let row = 0; row < this.rows; row++) {
      for (let col = 0; col < this.columns; col++) {
        if (this.startingPoint.x === col && this.startingPoint.y === row) { continue; }
        if (this.endingPoint.x === col && this.endingPoint.y === row) { continue; }
        if (this.blocks[row][col].isWall) { continue; }
        this.clear([col, row]);
      }
    }
    this.path = [];
    this.closedNodes = [];
    this.openNodes = new PriorityQueue();
  }

  clearWalls(): void {
    for (let row = 0; row < this.rows; row++) {
      for (let col = 0; col < this.columns; col++) {
        if (!this.blocks[row][col].isWall) { continue; }
        this.blocks[row][col].isWall = false;
        this.clear([col, row]);
      }
    }
  }

  createKey(node: Node): string {
    return 'x:' + node.x + ',y:' + node.y;
  }

  animateGraph(animations: Array<Array<number>>): void {
    if (this.currentNode.x !== this.endingPoint.x && this.currentNode.y !== this.endingPoint.y) {
      this.path = [];
    } else {
      this.path.unshift(this.currentNode);
      while (this.currentNode.x !== this.startingPoint.x || this.currentNode.y !== this.startingPoint.y) {
        this.currentNode = this.currentNode.parent;
        this.path.unshift(this.currentNode);
      }
    }
    this.animate(animations);
  }

  findPath(): void {
    const animations = [];
    switch (this.algorithm) {
      case 'None': 
        break;
      case 'DFS':
        this.dfs(animations);
        break;
      case 'BFS':
        this.bfs(animations);
        break;
      case 'A*':
        this.astar(animations);
        break;
      case 'Dijkstra':
        this.dijkstra(animations);
        break;
    }
    this.animateGraph(animations);
  }

  dijkstra(animations: Array<Array<number>>): void {
    this.isRunning = true;
    this.reset();
    this.currentNode = this.startingPoint;
    this.currentNode.g = 0;

    const visited = {};
    const queue = new PriorityQueue((a, b) => a.g <= b.g);
    queue.push(this.currentNode);
    visited[this.createKey(this.currentNode)] = true;

    while (queue.size() !== 0 && (this.currentNode.x !== this.endingPoint.x || this.currentNode.y !== this.endingPoint.y)) {
      this.currentNode = queue.pop();
      const currX = this.currentNode.x;
      const currY = this.currentNode.y;
      let distance = Math.abs(this.currentNode.x - this.endingPoint.x) + Math.abs(this.currentNode.y - this.endingPoint.y);
      if ((currX !== this.startingPoint.x || currY !== this.startingPoint.y) && (currX !== this.endingPoint.x || currY !== this.endingPoint.y)) {
        this.pushAnimationColor(animations, this.currentNode.x, this.currentNode.y, distance, true);
      }

      // North
      let next = new Node(false, currX, currY - 1);
      next.g = this.currentNode.g + 1;
      next.parent = this.currentNode;
      if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
        visited[this.createKey(next)] = true;
        queue.push(next);
        if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
        this.pushAnimationColor(animations, next.x, next.y, distance, false);
      }

      // West 
      next = new Node(false, currX - 1, currY);
      next.g = this.currentNode.g + 1;
      next.parent = this.currentNode;
      if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
        visited[this.createKey(next)] = true;
        queue.push(next);
        if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
        this.pushAnimationColor(animations, next.x, next.y, distance, false);
      }

      // South
      next = new Node(false, currX, currY + 1);
      next.g = this.currentNode.g + 1;
      next.parent = this.currentNode;
      if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
        visited[this.createKey(next)] = true;
        queue.push(next);
        if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
        this.pushAnimationColor(animations, next.x, next.y, distance, false);
      }

      // East 
      next = new Node(false, currX + 1, currY);
      next.g = this.currentNode.g + 1;
      next.parent = this.currentNode;
      if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
        visited[this.createKey(next)] = true;
        queue.push(next);
        if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
        this.pushAnimationColor(animations, next.x, next.y, distance, false);
      }

      if (this.useDiagonals) {
        // North-West
        next = new Node(false, currX - 1, currY - 1);
        next.g = this.currentNode.g + 1;
        next.parent = this.currentNode;
        if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
          visited[this.createKey(next)] = true;
          queue.push(next);
          if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
          this.pushAnimationColor(animations, next.x, next.y, distance, false);
        }

        // South-West
        next = new Node(false, currX - 1, currY + 1);
        next.g = this.currentNode.g + 1;
        next.parent = this.currentNode;
        if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
          visited[this.createKey(next)] = true;
          queue.push(next);
          if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
          this.pushAnimationColor(animations, next.x, next.y, distance, false);
        }

        // South-East
        next = new Node(false, currX + 1, currY + 1);
        next.g = this.currentNode.g + 1;
        next.parent = this.currentNode;
        if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
          visited[this.createKey(next)] = true;
          queue.push(next);
          if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
          this.pushAnimationColor(animations, next.x, next.y, distance, false);
        }

        // North-East
        next = new Node(false, currX + 1, currY - 1);
        next.g = this.currentNode.g + 1;
        next.parent = this.currentNode;
        if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
          visited[this.createKey(next)] = true;
          queue.push(next);
          if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
          this.pushAnimationColor(animations, next.x, next.y, distance, false);
        }
      }
    }
  }

  bfs(animations: Array<Array<number>>): void {
    this.isRunning = true;
    this.reset();
    this.currentNode = this.startingPoint;

    const visited = {};
    const queue = [];
    queue.push(this.currentNode);
    visited[this.createKey(this.currentNode)] = true;

    while (queue.length !== 0 && (this.currentNode.x !== this.endingPoint.x || this.currentNode.y !== this.endingPoint.y)) {
      this.currentNode = queue.shift();
      const currX = this.currentNode.x;
      const currY = this.currentNode.y;
      let distance = Math.abs(this.currentNode.x - this.endingPoint.x) + Math.abs(this.currentNode.y - this.endingPoint.y);
      if ((currX !== this.startingPoint.x || currY !== this.startingPoint.y) && (currX !== this.endingPoint.x || currY !== this.endingPoint.y)) {
        this.pushAnimationColor(animations, this.currentNode.x, this.currentNode.y, distance, true);
      }

      // North
      let next = new Node(false, currX, currY - 1);
      next.parent = this.currentNode;
      if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
        visited[this.createKey(next)] = true;
        queue.push(next);
        if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
        this.pushAnimationColor(animations, next.x, next.y, distance, false);
      }

      // West 
      next = new Node(false, currX - 1, currY);
      next.parent = this.currentNode;
      if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
        visited[this.createKey(next)] = true;
        queue.push(next);
        if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
        this.pushAnimationColor(animations, next.x, next.y, distance, false);
      }

      // South
      next = new Node(false, currX, currY + 1);
      next.parent = this.currentNode;
      if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
        visited[this.createKey(next)] = true;
        queue.push(next);
        if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
        this.pushAnimationColor(animations, next.x, next.y, distance, false);
      }

      // East 
      next = new Node(false, currX + 1, currY);
      next.parent = this.currentNode;
      if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
        visited[this.createKey(next)] = true;
        queue.push(next);
        if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
        this.pushAnimationColor(animations, next.x, next.y, distance, false);
      }

      if (this.useDiagonals) {
        // North-West
        next = new Node(false, currX - 1, currY - 1);
        next.parent = this.currentNode;
        if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
          visited[this.createKey(next)] = true;
          queue.push(next);
          if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
          this.pushAnimationColor(animations, next.x, next.y, distance, false);
        }

        // South-West
        next = new Node(false, currX - 1, currY + 1);
        next.parent = this.currentNode;
        if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
          visited[this.createKey(next)] = true;
          queue.push(next);
          if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
          this.pushAnimationColor(animations, next.x, next.y, distance, false);
        }

        // South-East
        next = new Node(false, currX + 1, currY + 1);
        next.parent = this.currentNode;
        if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
          visited[this.createKey(next)] = true;
          queue.push(next);
          if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
          this.pushAnimationColor(animations, next.x, next.y, distance, false);
        }

        // North-East
        next = new Node(false, currX + 1, currY - 1);
        next.parent = this.currentNode;
        if (this.isValid(next.x, next.y) && !this.isWall(next.x, next.y) && !visited[this.createKey(next)]) {
          visited[this.createKey(next)] = true;
          queue.push(next);
          if ((next.x === this.startingPoint.x && next.y === this.startingPoint.y) || (next.x === this.endingPoint.x && next.y === this.endingPoint.y)) continue;
          this.pushAnimationColor(animations, next.x, next.y, distance, false);
        }
      }
    }
  }

  dfs(animations: Array<Array<number>>): void {
    this.isRunning = true;
    this.reset();
    this.currentNode = this.startingPoint;

    const visited = {};
    visited[this.createKey(this.currentNode)] = true;

    this.dfsHelper(animations, this.currentNode, visited);
  }

  dfsHelper(animations: Array<Array<number>>, node: Node, visited: {}): boolean {
    if (this.endingPoint.x === node.x && this.endingPoint.y === node.y) return true;
    visited[this.createKey(new Node(false, node.x , node.y))] = true;
    let distance = Math.abs(this.currentNode.x - this.endingPoint.x) + Math.abs(this.currentNode.y - this.endingPoint.y);
    for (let yDiff = -1; yDiff <= 1; yDiff++) {
      for (let xDiff = -1; xDiff <= 1; xDiff++) {
        if (!this.useDiagonals && (xDiff !== 0 && yDiff !== 0)) { continue; }
        const newX = node.x + xDiff;
        const newY = node.y + yDiff;
        if (this.isValid(newX, newY) && !this.isWall(newX, newY) && !visited[this.createKey(new Node(false, newX, newY))]) {
          this.currentNode = new Node(false, newX, newY);
          this.currentNode.parent = node;
          if (this.currentNode.x !== this.endingPoint.x || this.currentNode.y !== this.endingPoint.y) {
            this.pushAnimationColor(animations, this.currentNode.x, this.currentNode.y, distance, true);
          }
          if (this.dfsHelper(animations, this.currentNode, visited)) return true;
        }
      }
    }
  }

  astar(animations: Array<Array<number>>): void {
    this.isRunning = true;
    this.reset();
    this.currentNode = this.startingPoint;
    this.addToClosedNodes(this.currentNode, animations);
    this.addNeighborsToOpenList(animations);

    while (this.currentNode.x !== this.endingPoint.x || this.currentNode.y !== this.endingPoint.y) {
      if (this.openNodes.size() === 0) {
        this.path = [];
        return;
      }
      this.currentNode = this.openNodes.pop();
      this.addToClosedNodes(this.currentNode, animations);
      this.addNeighborsToOpenList(animations);
    }
  }

  addNeighborsToOpenList(animations): void {
    const currX = this.currentNode.x;
    const currY = this.currentNode.y;

    for (let y = -1; y <= 1; y++) {
      for (let x = -1; x <= 1; x++) {
        if (!this.useDiagonals && (x !== 0 && y !== 0)) { continue; }
        if (this.isValid(currX + x, currY + y) && !this.isWall(currX + x, currY + y)) {
          if (!this.pqContains(this.openNodes, this.blocks[currY + y][currX + x]) &&
            !this.contains(this.closedNodes, this.blocks[currY + y][currX + x])) {
            this.blocks[currY + y][currX + x].parent = this.currentNode;
            this.blocks[currY + y][currX + x].g = this.addCost(this.currentNode.g);
            this.blocks[currY + y][currX + x].h = this.computeHeuristics(currX + x, currY + y, this.endingPoint.x, this.endingPoint.y);
            this.addToOpenNodes(this.blocks[currY + y][currX + x], animations);
          }
        }
      }
    }
    return;
  }

  pqContains(queue: PriorityQueue, node: Node) {
    return queue.contains(node);
  }

  contains(array: Array<Node>, node: Node): boolean {
    for (let i = 0; i < array.length; i++) {
      if (array[i].x === node.x && array[i].y === node.y) { 
        return true; 
      }
    }
    return false;
  }

  getNextNode(): Node {
    let currentSmallestValue = Infinity;
    let nextNode;
    for (let i = 0; i < this.openNodes.size(); i++) {
      const currentNode = this.openNodes[i];
      const heuristicValue = currentNode.h + currentNode.g;
      if (heuristicValue <= currentSmallestValue) {
        nextNode = currentNode;
        currentSmallestValue = heuristicValue;
      }
    }
    return nextNode;
  }

  addToOpenNodes(node, animations): void {
    this.openNodes.push(node);
    if (node.x === this.startingPoint.x && node.y === this.startingPoint.y) { return; }
    if (node.x === this.endingPoint.x && node.y === this.endingPoint.y) { return; }
    this.pushAnimationColor(animations, node.x, node.y, node.h, false);
  }

  addToClosedNodes(node, animations): void {
    this.closedNodes.push(node);
    if (node.x === this.startingPoint.x && node.y === this.startingPoint.y) { return; }
    if (node.x === this.endingPoint.x && node.y === this.endingPoint.y) { return; }
    this.pushAnimationColor(animations, node.x, node.y, node.h, true);
  }

  isTouchEvent(e): boolean {
    const browser = this.getBrowserName();
    if (browser === 'firefox') {
      return (window.TouchEvent && e.originalEvent instanceof TouchEvent) && e.type === 'touchmove';
    } else {
      return e instanceof TouchEvent && e.type === 'touchmove';
    }
  }

  getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
}
