<div class="content">
  <div class="wrapper-pathfinder">
    <mdb-navbar id="navbar" SideClass="navbar navbar-expand-lg navbar-dark" [containerInside]="false">
        <mdb-navbar-brand><a class="navbar-brand" href="#"></a></mdb-navbar-brand>
            <links>
            <ul class="nav ml-auto">
                <li class="nav-item"><span class="nav-link waves-light" mdbWavesEffect routerLink="/home"><mdb-icon fas icon="home"></mdb-icon> Home</span></li>
                <li class="nav-item"><span class="nav-link waves-light" mdbWavesEffect routerLink="/pathfinder"><mdb-icon fas icon="location-arrow"></mdb-icon> Pathfinder</span></li>
            </ul>
        </links>
    </mdb-navbar>
    <div id="stars"></div>
    <div id="stars2"></div>
    <div id="stars3"></div>
    <div class="container">
        <div class="row">
          <div class="col-sm-6 offset-sm-3">
            <mdb-card dark="true">
              <mdb-card-body class="elegant-color white-text">
                <mdb-card-title>
                  <h4>Sorting</h4>
                </mdb-card-title>
                <div class="form-group col-sm-12">
                  <div>
                    <select class="browser-default dark-select" (ngModelChange)="updateAlgorithm($event)" [disabled]="isRunning" [(ngModel)]="algorithm">
                        <option value="None">Select an algorithm</option>
                        <option value="Quicksort">Quicksort</option>
                        <option value="Bubble sort">Bubble sort</option>
                        <option value="Cocktail sort">Cocktail shaker sort</option>
                        <option value="Heap sort">Heap sort</option>
                        <option value="Merge sort">Merge sort</option>
                      </select>
                  </div>
                  <div>
                    <label>Visualization speed: </label>
                    <input type="range" [(ngModel)]="speed" (ngModelChange)="speedChange($event)" class="custom-range" value="30" min="0" max="40" />
                  </div>
                  <div [hidden]="algorithm === 'None'">
                    <div>
                        <label>{{ comparisons }} values compared</label>
                    </div>
                    <div>
                        <label>{{ swaps }} values swapped</label>
                    </div>
                    <div>
                        <label>Time taken to perform: {{ time | number :'1.1-1' }}ms</label>
                    </div>
                </div>
                  <div>
                    <button mdbBtn type="button" color="danger" class="waves-light col-sm-12" [disabled]="isRunning" (click)="shuffle()" mdbWavesEffect>Shuffle</button>
                    <button mdbBtn type="button" color="primary" class="waves-light col-sm-12" [disabled]="isRunning || !isNotConfigured" (click)="sort()" mdbWavesEffect>Sort</button>
                  </div>
                </div>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
      </div>
    <div class="array-container" #container>
    </div>
  </div>
</div>
