<div class="container">
  <section class="magazine-section my-5">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-xl-4" *ngFor="let skill of skills | keyvalue; index as i"
           data-aos="zoom-in-down"
           data-aos-duration="500"
           [attr.data-index]="i"
           [attr.data-aos-delay]="(i + 1) * 200"
           data-aos-easing="ease-in-sine">
        <div class="col-sm-12 h-500 skill-background">
          <h1>{{skill.key}}</h1>
          <hr class="background-primary">
          <div class="col-sm-12 h-415 overflow-y-auto scrollbar scrollbar-primary">
            <div *ngFor="let object of skill.value; index as j"
                 [attr.data-index]="j"
                 [attr.data-aos-offset]="-1 * j * 150 + i"
                 [attr.data-aos-delay]="(i + 1) * 200 + (j + 1) * 100"
                 data-aos="zoom-in-down"
                 data-aos-duration="500"
                 data-aos-easing="ease-in-sine">
              <app-skill
                level="{{object.level}}"
                yoe="{{object.yoe}}"
                technology="{{object.technology}}"
                logo="{{object.logo}}">
              </app-skill>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
