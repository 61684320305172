<style>
  @-webkit-keyframes chartjs-render-animation{from{opacity:0.99}to{opacity:1}}@keyframes chartjs-render-animation{from{opacity:0.99}to{opacity:1}}.chartjs-render-monitor{-webkit-animation:chartjs-render-animation 0.001s;animation:chartjs-render-animation 0.001s;}
</style>

<div class="content" role="main">
  <div class="sections">

    <mdb-navbar id="navbar" SideClass="navbar navbar-expand-lg navbar-dark fixed-top" [containerInside]="false">
      <mdb-navbar-brand><a class="navbar-brand" href="#"></a></mdb-navbar-brand>
      <links>
        <ul class="nav ml-auto">
          <li class="nav-item"><span class="nav-link waves-light" mdbWavesEffect (click)="scrollToElement('home')"><mdb-icon fas icon="home"></mdb-icon>Home</span></li>
          <li class="nav-item"><span class="nav-link waves-light" mdbWavesEffect (click)="scrollToElement('history')"><mdb-icon fas icon="file"></mdb-icon>History</span></li>
          <li class="nav-item"><span class="nav-link waves-light" mdbWavesEffect (click)="scrollToElement('skills')"><mdb-icon fas icon="star"></mdb-icon>Skills</span></li>
          <li class="nav-item"><span class="nav-link waves-light" mdbWavesEffect (click)="scrollToElement('contact')"><mdb-icon fas icon="envelope"></mdb-icon>Contact</span></li>
          <li class="nav-item dropdown" dropdown>
            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
              <mdb-icon fas icon="eye"></mdb-icon> Visualization<span class="caret"></span></a>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light nav-link" mdbWavesEffect routerLink="/pathfinder"><mdb-icon fas icon="location-arrow"></mdb-icon>Pathfinder</a>
              <a class="dropdown-item waves-light nav-link" mdbWavesEffect routerLink="/sorting"><mdb-icon fas icon="signal"></mdb-icon>Sorting</a>
            </div>
          </li>
        </ul>
      </links>
    </mdb-navbar>

    <div data-spy="scroll" data-target="#navbar" class="scrollspy-example z-depth-1">
      <section mdbScrollSpyWindow="home" id="home" class="section section-home">
        <div class="container align-items-center h-100">
          <div class="row align-item-center h-100">
            <div class="col-md-10 offset-md-1 d-none d-lg-block">
              <h1 class="display-1 intro">
                {{typedIntro}}
              </h1>
              <h1 class="display-4 intro">
                {{typedSummary}}
              </h1>
            </div>
            <div class="col-md-10 offset-md-1 d-none d-md-block d-lg-none">
              <h1 class="display-2 intro">
                {{typedIntro}}
              </h1>
              <h1 class="display-4 intro">
                {{typedSummary}}
              </h1>
            </div>
            <div class="col-md-12 d-sm-block d-md-none d-lg-none">
              <h1 class="display-4 intro">
                {{typedIntro}}
              </h1>
              <h1 class="intro">
                {{typedSummary}}
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section mdbScrollSpyWindow="history" id="history" class="section section-history">
        <div class="container align-items-center">
          <div class="row align-item-center">
            <app-history></app-history>
          </div>
        </div>
      </section>
      <section mdbScrollSpyWindow="skills" id="skills" class="section section-skills">
        <h1 class="intro">
          <app-skills></app-skills>
        </h1>
      </section>
      <section mdbScrollSpyWindow="contact" id="contact" class="section section-contact">
        <h1 class="intro">
          <app-contact></app-contact>
        </h1>
      </section>
    </div>
  </div>
</div>
