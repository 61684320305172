<div class="single-news">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-3 skill-thumbnail">
        <div class="align-image">
          <img class="img-fluid max-size-82 size-82" src="/assets/img/{{logo}}" alt="Sample image">
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-9" style="margin-left: auto; margin-right: auto;">
        <h3>{{technology}}</h3>
        <div class="d-flex justify-content-center">
          <div class="pl-0 mb-3">
            <p class="skill-description">
              {{yoe}} years of experience
              <br>
              <br>
              <span *ngFor="let star of stars" class="fa fa-star skill-star-size">
                </span>
              <span *ngFor="let star of blackStars" class="fa fa-star skill-star-size font-color-black">
                </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
