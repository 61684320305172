export class Node {
  public isWall : boolean;
  public distanceFromStart : number;
  public x : number;
  public y : number;
  public g : number;
  public h : number;
  public parent : Node;

  constructor(isWall : false, x: number, y: number) {
    this.isWall = isWall;
    this.x = x;
    this.y = y;
    this.g = 0;
    this.parent = null;
  }
}
