import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.scss']
})
export class SortingComponent implements OnInit {
  
  @ViewChild('container', {static: true}) container: ElementRef<HTMLDivElement>;

  private nbElements: number;
  private sortingSpeed: number;
  private timeDeduction: number;
  public speed: number;
  public algorithm: string;
  public isNotConfigured: boolean;
  public comparisons: number;
  public swaps: number;
  public time: number;
  public isRunning: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.algorithm = 'None';
    this.isRunning = false;
    this.speed = 30;
    this.sortingSpeed = 40.01 - this.speed;
    this.nbElements = Math.floor(this.container.nativeElement.clientWidth / 9) - 1;
    this.isNotConfigured = false;
    this.time = 0.00;
    this.swaps = 0;
    this.comparisons = 0;
    this.timeDeduction = 0;

    for (let i = 0; i <= this.nbElements; i++) {
      const current = document.createElement("div");
      current.setAttribute('id', '' + i);
      current.style.height = '' + (1 + i) * 0.24 + 'vh';
      current.className = 'array-column';
      this.container.nativeElement.appendChild(current);
    }

    this.shuffle();
  }

  speedChange(event): void {
    this.sortingSpeed = 40.01 - event;
  }

  updateAlgorithm(event): void {
    this.algorithm = event;
    this.isNotConfigured = event !== 'None';
  }

  shuffleArray(array): void {
    this.timeDeduction = 0;
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
  }

  shuffle(): void {
    const elementsArray = Array.prototype.slice.call(this.container.nativeElement.getElementsByClassName('array-column'));
    elementsArray.forEach((element) => {
      this.container.nativeElement.removeChild(element);
    });
    this.shuffleArray(elementsArray);
    elementsArray.forEach((element) => {
      this.container.nativeElement.appendChild(element);
    });
    for (let i = 0; i < elementsArray.length; i++) {
      elementsArray[i].style.background = '#DEB992';
    }
    this.time = 0;
    this.swaps = 0;
    this.comparisons = 0;
  }

  swap(array, i, j): void {
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  pushAnimations(animations: Array<Array<number>>, pivot: number, left: number, right: number, changes: number) {
    const animation = [];
    animation.push(pivot);
    animation.push(left);
    animation.push(right);
    animation.push(changes);
    animations.push(animation);
  }

  partition(array: Array<HTMLDivElement>, left: number, right: number, animations: Array<Array<number>>): number {
    let pivot = array[right];
    let i = left - 1;
    for (let j = left; j < right; j++) {
      if (array[j].clientHeight <= pivot.clientHeight) {
        i++;
        this.swap(array, i, j);
        this.pushAnimations(animations, right, i, j, 1);
      } else {
        this.pushAnimations(animations, right, i, j, 0);
      }
    }

    this.pushAnimations(animations, right, right, i + 1, 1);

    this.swap(array, i + 1, right);
    return i + 1;
  }

  quickSort(array: Array<HTMLDivElement>, left: number, right: number, animations: Array<Array<number>>): void {
    if (left < right) {
      let pivot = this.partition(array, left, right, animations);
      this.quickSort(array, left, pivot - 1, animations);
      this.quickSort(array, pivot + 1, right, animations);
    }
  }

  merge(left: Array<HTMLDivElement>, right: Array<HTMLDivElement>, original: Array<HTMLDivElement>,
     offset: number, animations: Array<Array<number>>): Array<HTMLDivElement> {
    const result = [];
    let leftIndex = 0;
    let rightIndex = 0;
    let nbSwap = 0;

    while (leftIndex < left.length && rightIndex < right.length) {
      if (left[leftIndex].clientHeight < right[rightIndex].clientHeight) {
        const before = performance.now();
        while (leftIndex < left.length && left[leftIndex].clientHeight < right[rightIndex].clientHeight) {
          result.push(left[leftIndex]);
          this.pushAnimations(animations, null, offset + rightIndex, offset - (left.length - leftIndex), 0);
          leftIndex++;
        }
        const after = performance.now();
        this.timeDeduction += after - before;
      } else {
        while (rightIndex < right.length && leftIndex < left.length && right[rightIndex].clientHeight < left[leftIndex].clientHeight) {
          result.push(right[rightIndex]);
          let i = offset + rightIndex;
          const before = performance.now();
          while (i > (offset - (left.length - leftIndex - nbSwap))) {
            const temp = original[i];
            original[i] = original[i - 1];
            original[i - 1] = temp;
            this.pushAnimations(animations, offset + right.length - 1, i, i - 1, 1);
            i--;
          }
          const after = performance.now();
          this.timeDeduction += after - before;
          nbSwap++;
          rightIndex++;
        }
      }
    }
    
    for (let i = leftIndex; i < left.length; i++) {
      result.push(left[i]);
      this.pushAnimations(animations, null, offset + rightIndex, offset - (left.length - leftIndex), 0);
    }

    for (let i = rightIndex; i < right.length; i++) {
      result.push(right[i]);
    }

    return result;
  }

  mergeSort(array: Array<HTMLDivElement>, original: Array<HTMLDivElement>, offset: number, animations: Array<Array<number>>): Array<HTMLDivElement> {
    if (array.length <= 1) return array;

    const middle = Math.floor(array.length / 2);
    const left = array.slice(0, middle);
    const right = array.slice(middle);

    const leftArray = this.mergeSort(left, original, offset, animations);
    const rightArray = this.mergeSort(right, original, offset + left.length, animations);
    const res = this.merge(leftArray, rightArray, original, offset + left.length, animations);

    return res;
  }

  bubbleSort(array: Array<HTMLDivElement>, animations: Array<Array<number>>): void {
    const length = array.length;
    let swapped = false;
    do {
      swapped = false;
      for (let i = 0; i < length - 1; i++) {
        if (array[i].clientHeight > array[i + 1].clientHeight) {
          const temp = array[i];
          array[i] = array[i + 1];
          array[i + 1] = temp;
          swapped = true;
          this.pushAnimations(animations, null, i, i + 1, 1);
        } else {
          this.pushAnimations(animations, null, i, i + 1, 0);
        }
      }
    } while (swapped);
  }

  cocktailSort(array: Array<HTMLDivElement>, animations: Array<Array<number>>): void {
    const length = array.length;
    let left = 0;
    let right = length - 1;
    do {
      let i = 0;
      for (i = left; i < right; i++) {
        if (array[i].clientHeight > array[i + 1].clientHeight) {
          const temp = array[i];
          array[i] = array[i + 1];
          array[i + 1] = temp;
          this.pushAnimations(animations, null, i, i + 1, 1);
        } else {
          this.pushAnimations(animations, null, i, i + 1, 0);
        }
      }
      for (let j = right; j > left; j--) {
        if (array[j].clientHeight < array[j - 1].clientHeight) {
          const temp = array[j];
          array[j] = array[j - 1];
          array[j - 1] = temp;
          this.pushAnimations(animations, null, j, j - 1, 1);
        } else {
          this.pushAnimations(animations, null, j, j - 1, 0);
        }
      }
      right--;
      left++;
    } while (left < right);
  }

  heapify(array: Array<HTMLDivElement>, index: number, length: number, animations: Array<Array<number>>): void {
    const left = 2 * index + 1;
    const right = 2 * index + 2;
    let max = index;

    if (left < length && array[left].clientHeight > array[max].clientHeight) {
      max = left;
    }
    if (right < length && array[right].clientHeight > array[max].clientHeight) {
      max = right;
    }

    if (max !== index) {
      this.pushAnimations(animations, null, index, max, 1);
      this.swap(array, index, max);
      this.heapify(array, max, length, animations);
    }
  }

  heapSort(array: Array<HTMLDivElement>, animations: Array<Array<number>>): void {
    let length = array.length;

    for (let i = Math.floor(length / 2); i >= 0; i--) {
      this.heapify(array, i, length, animations);
    }

    for (let i = length - 1; i > 0; i--) {
      this.pushAnimations(animations, null, 0, i, 1);
      this.swap(array, 0, i);
      length--;
      this.heapify(array, 0, length, animations);
    }
  }

  sort(): void {
    this.isRunning = true;
    this.time = 0.00;
    this.swaps = 0;
    this.comparisons = 0;
    this.timeDeduction = 0.00;
    const animations = [];
    const elementsArray = Array.prototype.slice.call(this.container.nativeElement.getElementsByClassName('array-column'));
    const before = performance.now();
    switch (this.algorithm) {
      case 'Quicksort':
        this.quickSort(elementsArray, 0, elementsArray.length - 1, animations);
        break;
      case 'Merge sort':
        this.mergeSort(elementsArray, elementsArray, 0, animations);
        break;
      case 'Bubble sort':
        this.bubbleSort(elementsArray, animations);
        break;
      case 'Cocktail sort':
        this.cocktailSort(elementsArray, animations);
        break;
      case 'Heap sort':
        this.heapSort(elementsArray, animations);
        break;
    }
    
    const after = performance.now();
    this.time = after - before - this.timeDeduction;
    this.animate(animations);
  }

  animate(animations: Array<Array<number>>): void {
    const elementsArray = Array.prototype.slice.call(this.container.nativeElement.getElementsByClassName('array-column'));
    this.animateSynchronously(animations, elementsArray).then(() => {
      this.printSorted(elementsArray).then(() => this.isRunning = false);
    });
  }

  async printSorted(array: Array<HTMLDivElement>) {
    for (let i = 0; i < array.length; i++) {
      await new Promise((resolve) => {
        setTimeout(() => {
          array[i].style.background = '#0DC125';
          resolve();
        }, 10);
      })
    }
  }

  async animateSynchronously(animations: Array<Array<number>>, elementsArray: Array<HTMLDivElement>) {
    for (let i = 0; i < animations.length; i++) {
      const current = animations[i];
      const pivot = current[0];
      const barOne = current[1];
      const barTwo = current[2];
      const changes = current[3];
      this.comparisons++;
      if (barOne === -1 || changes === 0) continue;
      if (changes === 1) this.swaps++;
      await new Promise((resolve) => {
          setTimeout(() => {
            const temp = elementsArray[barOne];
            elementsArray[barOne] = elementsArray[barTwo];
            elementsArray[barTwo] = temp;
            elementsArray[barOne].style.background = '#FF5F32';
            elementsArray[barTwo].style.background = '#FF5F32';
            if (pivot) {
              elementsArray[pivot].style.background = '#2B87FF';
            }
    
            const divTemp = document.createElement('div');
            elementsArray[barOne].parentNode.insertBefore(divTemp, elementsArray[barOne]);
            elementsArray[barTwo].parentNode.insertBefore(elementsArray[barOne], elementsArray[barTwo]);
            divTemp.parentNode.insertBefore(elementsArray[barTwo], divTemp);
            divTemp.parentNode.removeChild(divTemp);
            setTimeout(() => {
              elementsArray[barOne].style.background = '#DEB992';
              elementsArray[barTwo].style.background = '#DEB992';
              resolve();
            }, this.sortingSpeed * 2);
          }, this.sortingSpeed);
      });
    }
  }
}
