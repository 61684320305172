import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  public skills;

  // public keys = Object.keys(this.skills);

  constructor() { }

  ngOnInit(): void {
    this.skills = {
      Backend: [
        {
          technology: 'Spring',
          yoe: 4,
          logo: 'spring-boot.png',
          level: 5
        },
        {
          technology: 'ASP.NET Core',
          yoe: 1,
          logo: 'aspnet.png',
          level: 4
        },
        {
          technology: 'NodeJS',
          yoe: 2,
          logo: 'node.png',
          level: 4
        },
        {
          technology: 'Symfony',
          yoe: 1,
          logo: 'symfony.png',
          level: 4
        }
      ],
      Frontend: [
        {
          technology: 'Angular 2+',
          yoe: 2,
          logo: 'angular.png',
          level: 4
        },
        {
          technology: 'Vue',
          yoe: 1,
          logo: 'vue.png',
          level: 4
        },
        {
          technology: 'React',
          yoe: 1,
          logo: 'react.png',
          level: 4
        },
        {
          technology: 'Ember',
          yoe: 1,
          logo: 'ember.png',
          level: 3
        }
      ],
      Deployment: [
        {
          technology: 'Docker',
          yoe: 4,
          logo: 'docker.png',
          level: 5
        },
        {
          technology: 'AWS',
          yoe: 3,
          logo: 'aws-dep.png',
          level: 5
        },
        {
          technology: 'Kubernetes',
          yoe: 1,
          logo: 'kubernetes.png',
          level: 4
        },
        {
          technology: 'OpenStack',
          yoe: 0.5,
          logo: 'openstack.png',
          level: 3
        }
      ],
      'Data storing': [
        {
          technology: 'MySQL',
          yoe: 4,
          logo: 'mysql.png',
          level: 5
        },
        {
          technology: 'Postgres',
          yoe: 3,
          logo: 'postgres.png',
          level: 5
        },
        {
          technology: 'MongoDB',
          yoe: 1,
          logo: 'mongodb.png',
          level: 4
        },
        {
          technology: 'DynamoDB',
          yoe: 1,
          logo: 'aws-dep.png',
          level: 4
        },
      ],
      'Architecture': [
        {
          technology: 'REST',
          yoe: 4,
          logo: 'rest.png',
          level: 5
        },
        {
          technology: 'SOAP',
          yoe: 1,
          logo: 'soap.png',
          level: 4
        },
        {
          technology: 'Microservices',
          yoe: 4,
          logo: 'rest.png',
          level: 5
        },
        {
          technology: 'SOA',
          yoe: 4,
          logo: 'rest.png',
          level: 5
        }
      ],
      'Big Data': [
        {
          technology: 'Spark',
          yoe: 1,
          logo: 'spark.png',
          level: 4
        },
        {
          technology: 'Hadoop',
          yoe: 1,
          logo: 'hadoop.png',
          level: 4
        },
        {
          technology: 'Redis',
          yoe: 2,
          logo: 'redis.png',
          level: 4
        },
        {
          technology: 'Kafka',
          yoe: 1,
          logo: 'kafka.png',
          level: 4
        }
      ]
    };
  }
}
