<div class="container">
  <div class="row">
    <div *ngFor="let degree of degrees; index as i"
         [attr.data-index]="i"
         class="d-block col-sm-12 col-xl-6"
         data-aos="zoom-in-down"
         data-aos-duration="500"
         [attr.data-aos-delay]="(i + 1) * 300"
         data-aos-easing="ease-in-sine">
      <app-history-step
        company="{{degree.school}}"
        shortSummary="{{degree.shortSummary}}"
        imgSrc="{{degree.image}}"
        linkToCompany="{{degree.link}}"
        title="{{degree.title}}"
        startDate="{{degree.startDate}}"
        endDate="{{degree.endDate}}"
        [competencies]="degree.competencies">
      </app-history-step>
    </div>
    <div *ngFor="let exp of professionalExperiences; index as j"
         [attr.data-index]="j"
         class="d-block col-sm-12 col-md-6 col-xl-4"
         data-aos="zoom-in-down"
         data-aos-duration="500"
         [attr.data-aos-delay]="(j + 1) * 300"
         [attr.data-aos-offset]="-1 * j * 150"
         data-aos-easing="ease-in-sine">
      <app-history-step
        company="{{exp.company}}"
        shortSummary="{{exp.shortSummary}}"
        imgSrc="{{exp.image}}"
        linkToCompany="{{exp.link}}"
        title="{{exp.title}}"
        startDate="{{exp.startDate}}"
        endDate="{{exp.endDate}}"
        [competencies]="exp.competencies">
      </app-history-step>
    </div>
  </div>
</div>
